<template>
  <div class="invite-kol">
    <base-loader v-if="isDataLoading"></base-loader>
    <terms-popup
      v-if="showTermsAndConditionsPopup"
      :should-agreed="true"
      @closed="showTermsAndConditionsPopup = false"
      @agreed="storeUserAgreed"
    ></terms-popup>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { maxLength, minLength, required } from "@vuelidate/validators";
import BaseLoader from "@/components/base/BaseLoader";
import { InviteApi } from "@/office/invite-api";
import { GtagServiceHelper } from "@/services/gtag/gtag.service";
import { GA_EVENTS } from "@/const/ga.events";
import {
  LOGIN_USER,
  SET_AGREEMENT,
  SET_USER_COUNTRY_CODE,
  SET_USER_EMAIL,
  SET_USER_PASSWORD,
  SET_USER_PHONE,
} from "@/store/const/actions-types";

export default {
  name: "InviteUnchain",
  components: {
    TermsPopup: () => import("@/components/sign-up/Popup/TermsPopup"),
    BaseLoader,
  },
  inject: ["showErrorTip"],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    token: {
      type: String,
    },
  },
  metaInfo: {
    title: "Login",
  },
  data() {
    return {
      inn: "",
      isDataLoading: true,
      showTermsAndConditionsPopup: false,
      agreementParagraphs: [],
    };
  },
  validations() {
    return {
      inn: {
        required: required,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    };
  },
  async created() {
    await this.registerKol();
  },
  watch: {},
  computed: {},
  methods: {
    async storeUserAgreed(agreedData) {
      this.agreementParagraphs = agreedData.paragraphs;
      await this.$store.dispatch(SET_AGREEMENT, agreedData);
      await this.registerKol();
    },
    async registerKol() {
      let inviteDataResponse = null;

      try {
        this.isDataLoading = true;
        inviteDataResponse = await InviteApi.loadKolData(this.token, this.inn);
      } catch {
        this.isDataLoading = false;
        // this.showErrorTip(this.$t("invite.unchain.error.verify_inn"));
        await this.$router.push("/login");
        return;
      }

      if (this.agreementParagraphs.length === 0) {
        this.showTermsAndConditionsPopup = true;
        // this.showErrorTip(this.$t("tip.should_accept_terms"));
        return;
      }

      console.log("inviteDataResponse", inviteDataResponse.data);

      InviteApi.registerKol(
        inviteDataResponse.data.phone,
        inviteDataResponse.data.email,
        inviteDataResponse.data.pib,
        this.token
      )
        .then(async (r) => {
          GtagServiceHelper.sendEvent(this, GA_EVENTS.SIGN_UP);
          await this.$store.dispatch(
            SET_USER_PHONE,
            inviteDataResponse.data.phone
          );
          await this.$store.dispatch(
            SET_USER_EMAIL,
            inviteDataResponse.data.email
          );
          await this.$store.dispatch(SET_USER_PASSWORD, "");
          await this.$store.dispatch(SET_USER_COUNTRY_CODE, "UA");

          GtagServiceHelper.sendEvent(this, GA_EVENTS.SIGN_UP_OTP);
          await this.$store.dispatch(LOGIN_USER, {
            phone: inviteDataResponse.data.phone,
            accessToken: r.data.access_token,
            refreshToken: r.data.refresh_token,
          });

          await this.$router.push({
            path: "/sign-up/password",
          });
        })
        .catch((err) => {
          this.showErrorTip("User with given credentials exists.");
          console.log(err);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.invite-kol__form {
  width: 472px;
  max-width: 100%;
  box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
  background-color: #ffffff;
  border-radius: 24px;
  padding: 64px;

  &-title {
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    font-family: "Mic 32 New Rounded", sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
  }

  &-sub-title {
    font-size: pxToRem(14);
    line-height: pxToRem(18);
    margin-bottom: 54px;
    text-align: center;
    color: $gray1;
  }

  &-body {
    margin-bottom: 44px;
  }
}

@media screen and (max-width: 486px) {
  .login-form {
    box-shadow: none;
    width: 100%;
    padding: 0;
  }

  .invite-kol {
    margin-bottom: auto;

    &__form {
      width: 100%;
      padding: 0;
      box-shadow: unset;
    }
  }
}
</style>
