import axiosInstance from "@/services/http/axios-instance";

class _InviteApi {
  validateSecret(secretKey) {
    return axiosInstance.post("/invite/validate", {
      token: secretKey,
    });
  }

  loadData(secretKey, inn) {
    return axiosInstance.post("/invite/data", {
      token: secretKey,
      inn,
    });
  }

  loadKolData(secretKey) {
    return axiosInstance.post("/invite/kol/data", {
      token: secretKey,
    });
  }

  register(
    phone,
    email,
    fullName,
    token,
    inn,
    password = "",
    countryCode = "UA",
    termsAndCondVersion = "1.0"
  ) {
    return axiosInstance.post("/invite/register", {
      phone,
      email,
      full_name: fullName,
      password,
      c_password: password,
      country_code: countryCode,
      terms_and_cond_version: termsAndCondVersion,
      token,
      inn,
    });
  }

  registerKol(
    phone,
    email,
    fullName,
    token,
    password = "",
    countryCode = "UA",
    termsAndCondVersion = "1.0"
  ) {
    return axiosInstance.post("/invite/kol/register", {
      phone,
      email,
      full_name: fullName,
      password,
      c_password: password,
      country_code: countryCode,
      terms_and_cond_version: termsAndCondVersion,
      token,
    });
  }
}
export const InviteApi = new _InviteApi();
